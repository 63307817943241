<script setup lang="ts">
  interface Props {
    photos: {
      image: string
    }[]
  }

  const props = defineProps<Props>();

  const currentSlide = ref(0);

  const items = props.photos.filter((photo) => photo.image);

  const move = (direction: 'left'|'right') => {
    const nextIndex = direction === 'left' ? currentSlide.value - 1 : currentSlide.value + 1;
    if (nextIndex === -1 || nextIndex === items.length) {
      return;
    }
    currentSlide.value = nextIndex;
  };
</script>

<template>
  <div class="photo-carousel">
    <Carousel v-model="currentSlide">
      <Slide v-for="photo in items" :key="photo.image">
        <img
          class="carousel-photo"
          :src="photo.image"
          alt=""
          draggable="false"
        >
      </Slide>
    </Carousel>
    <div class="chevron-container left" @click="move('left')">
      <IconChevronDown class="nav-chevron" />
    </div>
    <div class="chevron-container right" @click="move('right')">
      <IconChevronDown class="nav-chevron" />
    </div>
    <div class="carousel-control-container">
      <div
        v-for="i in items.length"
        :key="i"
        :class="`control ${currentSlide === i-1 ? 'active':''}`"
        @click="currentSlide = i-1"
      ></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.photo-carousel {
  position: relative;
}
.carousel-photo {
  width: 100%;
  height: 435px;
  border-radius: 20px;
  object-fit: contain;
  @media (max-width: 767px) {
    object-fit: contain;
  }
  @media (max-width: 375px) {
    height: 279px;
  }
}
.left {
 z-index: 1;
 position: absolute;
 left: 16px;
 top: 50%;
 transform: translate(0,-50%);
 & .nav-chevron {
  transform: rotate(90deg);
 }
}
.right {
 z-index: 1;
 position: absolute;
 right: 16px;
 top: 50%;
 transform: translate(0,-50%);
 & .nav-chevron {
  transform: rotate(270deg);
 }
}
.chevron-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  user-select: none;
  @media (max-width: 768px) {
    display: none;
  }
}
.nav-chevron {
  font-size: 24px;
  margin: 0;
}
.carousel-control-container {
  z-index: 1;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translate(-50%,0);
  display: flex;
  gap: 8px;
  border-radius: 50px;
  background: linear-gradient(0deg, #9C9C9C 0%, #9C9C9C 100%), rgba(37, 37, 37, 0.55);
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(20px);
  padding: 8px 12px;
}
.control {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  opacity: 0.3;
  background-color: white;
  cursor: pointer;
}
.active {
  opacity: 1;
}
</style>
